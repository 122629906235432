import { showMsgRequest, eventsCustomSelects } from '@core-custom/mixins/geral'

export const mixFormGeral = {
  computed:
  {
    mqShallShowLeftSidebar: {
      get() {
        const namespace = (('LISTINGS_MODULE_NAME' in this) ? this.LISTINGS_MODULE_NAME : 'listings_form')
        return this.$store.getters[`${namespace}/mqShallShowLeftSidebar`]
      },
      set(newValue) {
        const namespace = (('LISTINGS_MODULE_NAME' in this) ? this.LISTINGS_MODULE_NAME : 'listings_form')
        this.$store.commit(`${namespace}/setMqShallShowLeftSidebar`, newValue)
      },
    },
    sizePaginationListviewResponsive() {
      const currentBreakPoint = this.$store.getters['app/currentBreakPoint']
      return ((currentBreakPoint === 'xs') ? 'lg' : 'md')
    },
  },
  methods: {
    ...showMsgRequest.methods,
    ...eventsCustomSelects.methods,
    renderHtmlToTxt(value) {
      let txt = value
      if (txt) {
        txt = this.$options.filters.nl2br(txt)
      }

      return txt || '-----'
    },
    renderObjToTxt(value, field) {
      if (Array.isArray(value)) {
        let txt = ''
        value.forEach(o => {
          if ((o !== null) && (o !== undefined) && (typeof o === 'object') && (field in o)) {
            txt += `${o[field]}, `
          }
        })

        return txt.slice(0, -2) || '-----'
      }

      let txt = ''
      if ((value !== null) && (value !== undefined) && (typeof value === 'object') && (field in value)) {
        txt = value[field]
      }

      return txt || '-----'
    },
    renderArrayToTxt(value, field) {
      const aValue = value
      let txt = ''

      if ((aValue !== null) && (aValue !== undefined) && (aValue.length > 0)) {
        aValue.forEach(o => {
          if (field in o) {
            txt += ((txt) ? ', ' : '') + o[field]
          }
        })
      }

      return txt || '-----'
    },
    renderNameVenture(idVenture) {
      const ventureSel = this.load_data_geral.enterprises.find(o => Number(o.id) === Number(idVenture))

      return this.renderNameVentureHtml(ventureSel)
    },
    renderNameVentureHtml(ventureSel) {
      let sNameVenture = ''

      if (ventureSel?.id !== undefined) {
        if ((ventureSel?.code !== undefined) && (ventureSel?.name !== undefined) && (ventureSel.code !== '') && (ventureSel.name !== '')) {
          sNameVenture += `${ventureSel.code} - ${this.truncateTxt(ventureSel.name, 75)}`
        } else if ((ventureSel?.code !== undefined) && (ventureSel.code !== '')) {
          sNameVenture += `${ventureSel.code}`
        } else if ((ventureSel?.name !== undefined) && (ventureSel.name !== '')) {
          sNameVenture += `${this.truncateTxt(ventureSel.name, 75)}`
        }

        if ((ventureSel?.statusID !== undefined) && (ventureSel?.status !== undefined) && ((Number(ventureSel.statusID) !== 1) && (Number(ventureSel.statusID) !== 9) && (ventureSel.status !== ''))) {
          sNameVenture += ` (${ventureSel.status})`
        }
      }

      return sNameVenture || '-----'
    },
    truncateTxt(text, num) {
      const newText = text.split('').slice(0, num).join('')
      return newText
    },
  },
  filters: {
    nl2br(text) {
      if (text) {
        return text.replace(/(?:\r\n|\r|\n)/g, '<br />')
      }

      return ''
    },
    getTxt(value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          let txt = ''

          value.forEach(o => {
            if ((o !== null) && (o !== undefined)) {
              txt += `${o}, `
            }
          })

          return txt.slice(0, -2) || '-----'
        }
        return '-----'
      }

      if ((value !== null) && (value !== undefined) && (value !== '')) {
        return value
      }
      return '-----'
    },
    truncate(text, num) {
      const newText = text.split('').slice(0, num).join('')
      return newText
    },
    lowerBold(value) {
      if (!value) return ''
      return `<b>${value.toLowerCase()}</b>`
    },
  },
}

export const _ = null
